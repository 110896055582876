var eyeAble_pluginConfig = {
    "blacklistContrast": ".go-to-new-page-icon,.icon_job_search ,.icon_personal ,.icon_location ,.icon_login ,.icon_close_menu ,.go-to-new-page,.contact-options-wrapper,.icon-calendar,#contact-us-button-close ",
    "blacklistFontsize": ".static-flyout-link,.icon-chevron-down,.ui-autocomplete > li,.layout_2080,.cy_header__title--white ,#search ,.description,.jx_ui_html_tr",
    "blacklistFontsizeMobile": ".headline-content-template ",
    "forceWhiteBackground": ".logo__hays__claim,.sharing__list > li,#plat-header_mobile-hamburger",
    "mainIconAlt": 2,
    "topPosition": "200px",
    "pluginPath" : "/o/haystheme/EyeAble_V1.9.20/",
    "license": {
        "www.hays.de": "22f084f6e485051fepkmngar8",
        "*.hays.de": "c4c9a827c377a49depkmngar<",
        "*.hays.ch": "979b92358e39ba23epkmngar6",
        "*.hays.at": "07924fa6570ccecbepkmngarj",
        "*.hays.dk": "08e162e7a3602d30epkmngar6",
        "hayscareer.net": "ac02cd0903b77fd6epkmngarh",
        "haysworld.de": "5bfba4154640072depkmngar8",
        "hays-expert4experts.de": "080a0048e542a965epkmngar4",
        "haysworld.cyperfection.de": "55e763768de45e1eepkmngar7"
    }
}

//fix for voice over problems
document.addEventListener("eyeable:init_completed", function () {
    if(document.getElementById("eyeAble_container_ID").getAttribute("aria-modal")){
        document.getElementById("eyeAble_container_ID").removeAttribute("aria-modal");
    }
});